import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { Button } from "semantic-ui-react";

export default function Text({name}) {
    const [value, setValue] = useState('');

    useEffect(() => {
        fetch('https://api.the-jeals.com/texts.php?key=' + name)
        .then(res => res.json())
        .then(data => {
            setValue(data.content)
        });
    }, [name]);

    function save() {
        const data = new FormData();
        data.append("key", name);
        data.append("content", value);
        fetch('https://api.the-jeals.com/texts.php', {
            method: 'POST',
            body: data,
            credentials: 'include'
        })
        .then(res => {
            if(res.ok) {
                alert("saved")
            } else {
                alert("could not save");
            }
        });
    }

    return <>
        <ReactQuill theme="snow" value={value} onChange={setValue} style={{height: "40vh"}} />
        <Button onClick={save} style={{marginTop: "6vh"}} primary>Speichern</Button>
    </>
}