import styled from "styled-components";
import { Mobile, bpMobileQuery, bpDesktopQuery, Desktop } from "../breakpoints";
import { SpacerDown, SpacerUp } from "./Spacer";

export default function Banner() {
    return (
        <StyledBanner id="banner">
            <Desktop>
                <img src="img/V8-5.png" alt="Cover of Future II"/>

                <span className="text">
                    <span className="subtitle">Coming Soon</span>
                </span>
            </Desktop>

            <Mobile>
            <video autoPlay muted loop>
                <source src="img/mobile-bg.mp4" />
            </video>
            </Mobile>


            <SpacerDown/>
            <SpacerUp/>
        </StyledBanner>
    )
}

const StyledBanner = styled.section`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: inset 0 0 20vh #000b;
    overflow: hidden;

    &::before {
        content: '';
        width: 100vw;
        height: 100vh;
        display: block;

        background-image: url('img/band-foto-3-fertig.png');
        background-size: cover;
        background-position: center;

        z-index: -1;
        position: absolute;
    }

    img {
        width: 35vh;
        height: 35vh;
        margin-top: 20vh;

        box-shadow: 0 0 20vh #33005599;
        
        animation: move-up 0.5s forwards;
        transform: translateY(100vh);
        opacity: 0;
    }

    .text {
        display: flex;
        flex-direction: column;
        align-items: center;

        font-family: var(--font-0);
        font-weight: 400;
        font-style: normal;

        animation: move-up 0.5s forwards;
        transform: translateY(100vh);
        opacity: 0;
    }

    .suptitle {
        margin-top: 3.5vh;
        color: var(--fg-light-1);

        font-size: 6vw;
        font-family: var(--font-1);
    }

    .title {
        margin-top: 1vh;
        color: var(--fg-light-0);

        font-size: 13vw;
        font-family: var(--font-0);
        font-weight: 400;
        font-style: normal;
    }

    .subtitle {
        margin-top: 1vh;
        color: var(--fg-light-1);
        font-size: 4vw;
    }

    .slash {
        position: relative;
        color: var(--purple);

        &:nth-child(1) {
            margin-left: 2vw;
            top: -1vw;
        }
        &:nth-child(2) {
            margin-left: -5vw;
            margin-right: 2vw;
            top: 1vw;
        }
    }

    @media ${bpMobileQuery} {
        background-color: unset;
        box-shadow: none;
        height: 100svh;
        background-image: url('img/jeals-logo.png');
        background-position: center;
        background-size: 75vw auto;
        background-repeat: no-repeat;
        background-attachment: scroll;

        &::before {
            display: none;
        }

        video {
            display: block;
            position: fixed;
            right: 0;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
            z-index: -100;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    @media ${bpDesktopQuery} {
        .text {
            animation: unset;
            transform: unset;
            opacity: unset;
        }
        .subtitle {
            margin-top: 25vh;
            color: var(--fg-light-0);
            font-size: 3vh;
            font-family: var(--font-1);
            font-weight: 600;
            font-style: normal;
            border: 0.5vh solid var(--fg-light-0);
            padding: 1vh 5vh;
            border-radius: 0.3vh;
            backdrop-filter: blur(1vh);
            box-shadow: 0 0 2vh #000;
            text-shadow: 0 0 1vh #000;

            animation: move-up 0.5s forwards;
            transform: translateY(100vh);
            opacity: 0;
        }
    }
`;
