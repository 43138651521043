import { useCookies } from "react-cookie";
import styled from "styled-components";
import Login from "./Login";
import Tourdata from "./Tourdata";
import Text from "./Text";
import User from "./User";

import { Container, Tab } from "semantic-ui-react";
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/tab.min.css';
import 'semantic-ui-css/components/menu.min.css';
import 'semantic-ui-css/components/label.min.css';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/card.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/grid.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/table.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/input.min.css';

export default function Admin() {
    const [cookies] = useCookies(['auth']);
    
    if(cookies.auth === undefined) {
        return <Login />;
    } else {
        return (
            <StyledAdmin>
                <Container>
                    <Tab menu={{secondary: true, pointing: true}} panes={[
                        {
                            menuItem: 'Tourdata',
                            render: () => <Tourdata />
                        },
                        {
                            menuItem: 'Band',
                            render: () => <Text name="band" />
                        },
                        {
                            menuItem: 'Bandgeschichte',
                            render: () => <Text name="band-history"></Text>
                        },
                        {
                            menuItem: 'User',
                            render: () => <User />
                        }
                    ]} />
                </Container>

            </StyledAdmin>
        );
    }
}

const StyledAdmin = styled.div`
    background-color: white;
    min-height: 100svh;
`;