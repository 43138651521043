import { useEffect, useRef, useState } from "react";
import { Desktop, Mobile, bpDesktopQuery, bpMobileQuery } from "../breakpoints";
import styled, { css } from "styled-components";
import Modal from 'react-modal';
import { SpacerDown, SpacerUp } from "./Spacer";

export default function Tourdata() {
    const [tourdata, setTourdata] = useState([]);
    const [oldShows, setOldShows] = useState(false);
    const [timer, setTimer] = useState(null)
    const container = useRef(null);

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    function stopScroll() {
        if(timer !== null) clearInterval(timer);
    }

    function scroll(direction) {
        stopScroll();
        setTimer(setInterval(() => smoothScroll(container.current, container.current.scrollLeft + direction * 100, 200), 200));
    }

    function smoothScroll(elem, pos, time) {
        const current = elem.scrollLeft;
        let start = null;
        window.requestAnimationFrame(function step(currentTime) {
            start = !start ? currentTime : start;
            const progress = currentTime - start;
            if(current < pos) {
                elem.scrollTo(((pos - current) * progress / time) + current, 0);
            } else {
                elem.scrollTo(current - ((current - pos) * progress / time), 0);
            }

            if(progress < time) {
                window.requestAnimationFrame(step);
            } else {
                elem.scrollTo(pos, 0);
            }
        });
    }

    function handleScroll(e) {
        const elem = container.current;
        if(e.deltaY !== 0) {
            e.preventDefault();
            if (e.deltaY > 0) elem.scrollTo(elem.scrollLeft + 100, 0);
            else elem.scrollTo(elem.scrollLeft - 100, 0);
        }
    }

    function toggleOldShows(e) {
        setOldShows(!oldShows);
    }

    useEffect(() => {
        fetch('https://admin.the-jeals.com/api/content/items/tourdata?sort={date:-1}')
        .then(res => res.json())
        .then(data => {
            setTourdata(data)
        });

        container.current.addEventListener('wheel', handleScroll)
    }, []);

    return (
        <StyledTourdata id="tourdata">
            <Desktop><CircleDivider /></Desktop>
            <Mobile>
                <h1 className="header">Upcoming Shows</h1>
            </Mobile>

            <div ref={container} className="container">
                <Desktop>
                    <EmptyTourdataElement/>
                        {tourdata.map((td, idx) => <TourdataItem key={td.id} index={idx} tourdata={td} />)}
                    <EmptyTourdataElement/>
                </Desktop>
                <Mobile>
                    { tourdata.filter(td => Date.parse(td.date) >= now).reverse().map((td, idx) => <TourdataItem key={td.id} index={idx} tourdata={td} />) }
                </Mobile>
            </div>

            <Desktop>
                <CircleDivider />
                <div className="container-left" onMouseEnter={() => scroll(-1)} onMouseLeave={stopScroll}>&lt;</div>
                <div className="container-right" onMouseEnter={() => scroll(1)} onMouseLeave={stopScroll}>&gt;</div>
            </Desktop>

            <Mobile>
                <span className="old-shows" onClick={toggleOldShows}>Vergangene Shows</span>

                <div ref={container} className="container">

                <Mobile>
                    { tourdata.filter(td => Date.parse(td.date) < now).map((td, idx) => <TourdataItem key={td.id} index={idx} tourdata={td} />) }
                </Mobile>
            </div>
            </Mobile>

            <Mobile><SpacerDown/></Mobile>
            <Mobile><SpacerUp/></Mobile>
        </StyledTourdata>
    )
}

function EmptyTourdataElement() {
    return  (
        <TourdataElement>
            <div className="empty"/>
            <div className="bar"/>
            <div className="empty"/>
        </TourdataElement>
    );
}

function TourdataItem({index, tourdata}) {
    const src = "https://admin.the-jeals.com/storage/uploads" + tourdata.image.path;
    const elem = (
        <StyledTourdataItem up={(index % 2 === 0).toString()} className={index % 2 === 0 ? "up" : "down"}>
            <span className="date">{tourdata.date}</span>
            <span className="location">{tourdata.location}</span>
            <img src={src} alt="" />
        </StyledTourdataItem> 
    )

    const [modalIsOpen, setIsOpen] = useState(false);
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '',
            padding: ''
        },
    };

    return (
        <>
            <Desktop>
                <TourdataElement onClick={() => setIsOpen(true)}>
                    {index % 2 === 0 ? elem : <div className="empty"/>}
                    <div className="bar"/>
                    {index % 2 !== 0 ? elem : <div className="empty"/>}
                </TourdataElement>
                <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} style={customStyles} ariaHideApp={false}>
                    <TourdataPopup tourdata={tourdata} />
                </Modal>
            </Desktop>
            <Mobile>
                <TourdataElement>
                    <img src={src} alt=""/>
                    <div className="text">
                        <span className="date">{tourdata.date}</span>
                        <span className="location">{tourdata.location}</span>
                    </div>
                </TourdataElement>
            </Mobile>
        </>
    )
}

function TourdataPopup({tourdata}) {
    const src = "https://admin.the-jeals.com/storage/uploads" + tourdata.image.path;

    return (
        <StyledTourdataPopup>
            <img src={src} alt="" />
            <span className="date">{tourdata.date}</span>
            <span className="location">{tourdata.location}</span>
        </StyledTourdataPopup>
    )
}

const StyledTourdata = styled.section`
    width: 100vw;
    background-color: var(--bg-dark-0);
    display: flex;
    padding: 5vh 0;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    position: relative;

    .container {
        width: 80vw;
        font: var(--font-1);
        color: var(--fg-light-0);
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;
        transition: linear 1s;
    }

    .container-left {
        position: absolute;
        top: 28.25vh;
        left: 10vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--fg-light-1);
        height: 4vh;
        width: 4vh;
        font-family: var(--font-1);
        font-weight: 200;
        font-style: normal;
        font-size: 4vh;
        margin: auto 0;
        cursor: pointer;
        color: black;
    }
    .container-right {
        position: absolute;
        top: 28.25vh;
        right: 10vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--fg-light-1);
        height: 4vh;
        width: 4vh;
        font-family: var(--font-1);
        font-weight: 200;
        font-style: normal;
        font-size: 4vh;
        margin: auto 0;
        cursor: pointer;
        color: black;
    }

    .header {
        font-family: var(--font-1);
        font-weight: 700;
        font-size: 7vw;
        margin-bottom: 0;
    }

    .old-shows {
        font-family: var(--font-1);
        font-size: 6;
        font-weight: 400;
        margin: 1.5vh 0 2.5vh 0;
        text-decoration: underline;
    }

    @media ${bpMobileQuery} {
        height: 150vh;
        justify-content: center;
        flex-direction: column;
        background: unset;
        background-color: #060012aa;
        backdrop-filter: blur(0.5vh);

        .container {
            height: 80vh;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding-right: 10vw;
            margin-left: 10vw;
            gap: 0 10vw;
            color: var(--fg-light-0);
            overflow: auto;
        }

        .header {
            color: var(--fg-light-0);
        }

        .old-shows {
            color: var(--fg-light-0);
        }
    }
`;

const CircleDivider = styled.div`
    background: radial-gradient(ellipse at center, var(--fg-light-1) 0%, var(--fg-light-1) 30%, transparent 35%);
    background-repeat: repeat-x;
    background-size: 3vh 3vh;
    height: 3vh;
    width: calc(100vw - 9vh);
    margin: 0.9vh 3vh 1.4vh 3vh;
    overflow: hidden;
`

const TourdataElement = styled.div`
    width: 20vh;
    flex-shrink: 0;
    cursor: pointer;

    &:hover > .up, &:hover > .down, &:hover > .up img, &:hover > .down img {
        border-color: #629;
    }

    .empty {
        height: 19.25vh;
        width: 100%;
    }
    .bar {
        height: 1.5vh;
        width: 100%;
        background-color: var(--fg-light-1);
    }

    @media ${bpMobileQuery} {
        display: flex;
        flex-direction: row;
        width: 79.75vw;
        gap: 5vw;
        border-bottom: 0.25vh solid var(--fg-light-1);

        img {
            width: 12vh;
            height: 12vh;
            margin: 2vh;
            flex-shrink: 0;
            object-fit: cover;
        }

        .text {
            display: flex;
            flex-direction: column;
            width: calc(64vh - 5vw);
            overflow: none;
            font-family: var(--font-1);
            gap: 1vh;
            margin-top: 1vh;
            color: var(--fg-light-0);

            .date {
                font-weight: 500;
                font-size: 4.5vw;
                margin-top: 2vh;
            }

            .location {
                font-weight: 300;
                font-size: 3.5vw;
            }
        }
    }
`;

const StyledTourdataItem = styled.div`
    @media ${bpDesktopQuery} {
        height: 17.25vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-left: 0.25vh solid var(--fg-light-1);
        padding: 1vh;
        position: relative;
        ${props => props.up === 'true' && css`
            justify-content: end;
        `}

        img {
            width: 10vh;
            height: 10vh;
            object-fit: cover;
            border: 1vh solid var(--fg-light-1);
            border-radius: 50%;
            position: absolute;
            left: -6vh;
            background-color: var(--fg-light-1);
            ${props => props.up === 'false' ? css`bottom: 0;` : css`top: 0;`}
            
        }

        .date {
            font-size: 2vh;
            font-weight: 200;
        }

        .location {
            font-size: 1.75vh;
            font-weight: 200;
            white-space: nowrap;
        }
    }
`;

const StyledTourdataPopup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vh;

    img {
        max-width: 50vw;
        max-height: 80vh;
    }

    .date {
        font: var(--font-1);
        font-weight: 200;
        font-size: 2vh;
    }

    .location {
        font: var(--font-1);
        font-weight: 200;
        font-size: 1.75vh;
    }
`;
