import '@splidejs/react-splide/css';
import { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Modal from 'react-modal';
import styled from "styled-components";

export default function Instagram() {
    const [instagram, setInstagram] = useState([]);
    useEffect(() => {
        fetch('https://api.the-jeals.com/instagram.php')
            .then(res => res.json())
            .then(data => {
                setInstagram(data)
            })
    }, []);

    return (
        <StyledInstagram options={{
            type: "slide",
            perMove: 1,
            gap: '30px',
            pagination: false,
            fixedWidth: '15vw',
            snap: true,
            drag: 'free',
            lazyLoad: 'sequential'
        }}>
            {instagram.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map(inst =>
                <InstagramElement key={inst.shortCode} media={inst} />
            )}
        </StyledInstagram>
    );
}

function InstagramElement({ media }) {
    let icon;
    switch (media.type) {
        case 'Video':
            icon = <div className="slide-video"></div>
            break;
        case 'Sidecar':
            icon = <div className="slide-sidecar"></div>
            break;
        default:
            icon = <div></div>
    }

    const [modalIsOpen, setIsOpen] = useState(false)
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '',
            padding: ''
        },
    };

    return <>
        <SplideSlide onClick={() => setIsOpen(true)}>
            <img data-splide-lazy={"https://api.the-jeals.com/media_proxy.php?url=" + encodeURIComponent(media.displayUrl)} alt="" />
            <div className="instagram-hover">
                {media.caption}
            </div>
            {icon}
        </SplideSlide>
        <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} style={customStyles} ariaHideApp={false}>
            <InstagramPopup media={media} />
        </Modal>
    </>
}

function InstagramPopup({ media }) {
    let time = new Date(media.timestamp).toLocaleDateString('de-DE', { year: "numeric", month: "long", day: "numeric" })

    return <StyledPopup>
        <div className="media">
            <InstagramMedia media={media} />
        </div>
        <div className="content">
            <div className="header">
                <span className="profile">the_jeals</span>
                <span className="time">{time}</span>
                <a className="link" href={media.url} target="_blank" rel="noreferrer">
                    <img src="img/instagram.svg" alt="instagram" />
                </a>
            </div>
            <div className="caption">
                {media.caption}
            </div>
        </div>
    </StyledPopup>;
}

function InstagramMedia({ media }) {
    if (media.type === 'Image') {
        let src = `https://api.the-jeals.com/media_proxy.php?url=${encodeURIComponent(media.displayUrl)}`
        return <img src={src} alt="" />
    } else if (media.type === 'Video') {
        let src = `https://api.the-jeals.com/media_proxy.php?url=${encodeURIComponent(media.videoUrl)}`
        return <video autoPlay controls>
            <source src={src} />
        </video>
    } else {
        return <Splide options={{ type: 'slide', perMove: '1', perPage: '1', height: '50vh', width: '50vh' }}>
            {media.childPosts.map(m => {
                return <SplideSlide key={m.shortCode}>
                    <InstagramMedia media={m} />
                </SplideSlide>
            })}
        </Splide>
    }
}

const StyledInstagram = styled(Splide)`
    padding: 2vh 4vh;
    background-color: var(--bg-dark-0);

    img {
        height: 15vw;
        width: 15vw;
        transition: all ease-in 0.25s;
        cursor: pointer;
        object-fit: cover;
        /* filter: sepia(50%) brightness(0.7) hue-rotate(-30deg); */
    }
    img:hover {
        opacity: 0.25;
        filter: none;
    }
    img:hover + .slide-sidecar, img:hover + .slide-video {
        opacity: 0.25;
    }

    .instagram-hover {
        height: 12vw;
        width: 12vw;
        padding: 1.5vw;
        background-color: white;
        color: black;
        text-align: center;
        font-family: var(--font-1);
        font-weight: 300;
        font-style: normal;
        font-size: 1vw;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        overflow: hidden;
        display: none;
    }

    img:hover + .instagram-hover {
        display: block !important;
    }

    .slide-sidecar {
        width: 1vw;
        height: 1vw;
        position: absolute;
        top: 0.5vw;
        right: 0.5vw;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 0.25vw 0.5vw;
        background-image: url('img/sidecar.svg');
        background-size: 1vw;
        background-position: center;
        background-repeat: no-repeat;
        transition: opacity ease-in 0.25s;
    }
    .slide-video {
        width: 1vw;
        height: 1vw;
        position: absolute;
        top: 0.5vw;
        right: 0.5vw;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 0.25vw 0.5vw;
        background-image: url('img/video.svg');
        background-size: 1vw;
        background-position: center;
        background-repeat: no-repeat;
        transition: opacity ease-in 0.25s;
    }
`;

const StyledPopup = styled.div`
    width: 90vh;
    height: 50vh;
    display: flex;
    background-color: black;

    .media {
        width: 50vh;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 50vh;
            height: 50vh;
            object-fit: contain;
        }
        video {
            width: 50vh;
            height: 50vh;
            object-fit: contain;
        }
    }

    .content {
        width: 40vh;
        height: 50vh;
        font-family: "Poppins", sans-serif;
        font-weight: 200;
        font-style: normal;
        font-size: 1.5vh;
        color: white;
    }
    .header {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 2vh;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        height: 4.5vh;
    }
    .profile {
        font-weight: 400;
    }
    .link {
        position: absolute;
        top: 2vh;
        right: 2vh;
        img {
            width: 2vh;
            height: 2vh;
        }
    }
    .caption {
        font-size: 1.7vh;
        padding: 2vh;
        overflow-y: auto;
        height: 35.5vh;
    }
`;