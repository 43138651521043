import { useRef } from "react";
import styled from "styled-components";

import { Button, Card, CardContent, Form, FormField, Grid, GridColumn, Input } from "semantic-ui-react";


export default function Login() {
    const userRef = useRef(null);
    const passRef = useRef(null);

    function login() {
        const data = new FormData();
        data.append("user", userRef.current.inputRef.current.value);
        data.append("pass", passRef.current.inputRef.current.value);

        fetch('https://api.the-jeals.com/login.php', {
            method: 'POST',
            body: data,
            credentials: 'include'
        })
        .then(res => {
            if(res.ok) {
                window.location.reload();
            } else {
                alert("wrong credentials")
            }
        });
    }

    return (
        <StyledLogin>
            <Grid columns={3} centered>
                <GridColumn >
                    <Card fluid>
                        <CardContent>
                            <Form>
                                <FormField>
                                    <label>User</label>
                                    <Input ref={userRef} type="text" placeholder="User"/>
                                </FormField>

                                <FormField>
                                    <label>Password</label>
                                    <Input ref={passRef} type="password" placeholder="Password"/>
                                </FormField>

                                <Button type="submit" onClick={login} primary>Login</Button>
                            </Form>
                        </CardContent>
                    </Card>
                </GridColumn>
            </Grid>
        </StyledLogin>
    );
}


const StyledLogin = styled.div`
    background-color: white;
    min-height: 100svh;
    padding-top: 10vh;
`;