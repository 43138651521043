import styled from 'styled-components';
import Menu from './Main/Menu';
import { bpDesktopQuery } from './breakpoints';
import { useEffect, useState } from 'react';

export default function Bandhistory() {
    const [value, setValue] = useState('');

    useEffect(() => {
        fetch('https://admin.the-jeals.com/api/content/item/texts?filter={key:"band-history"}')
        .then(res => res.json())
        .then(data => {
            setValue(data.content)
        });
    }, []);

    return (
        <>
            <Menu />

            <StyledBandHistory>
                <h1>Bandgeschichte</h1>
                <div dangerouslySetInnerHTML={{__html: value}}></div>
            </StyledBandHistory>
        </>
    );
}

const StyledBandHistory = styled.section`
    width: 90vw;
    min-height: 100vh;
    padding: 5vw;

    background-color: var(--bg-dark-0);
    color: var(--fg-light-0);

    font-family: var(--font-1);
    font-size: 4vw;
    font-weight: 200;
    font-style: normal;

    h1 {
        text-align: center;
        font-size: 7vw;
        font-weight: 600;
    }

    h2 {
        font-size: 6vw;
        font-weight: 400;
        margin-bottom: 0;
    }

    p {
        margin-top: 0;
        margin-bottom: 5vh;
        text-align: justify;
    }

    @media ${bpDesktopQuery} {
        font-size: 2vh;
        width: 80vh;
        padding: 5vh calc((100vw - 80vh) / 2);

        h1 {
            font-size: 7vh;
        }
        h2 {
            font-size: 5vh;
        }
    }
`;