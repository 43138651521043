import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import styled from "styled-components";
import { Desktop, Mobile, bpDesktopQuery, bpMobileQuery, useMobileLandscapeQuery } from "../breakpoints"

import Banner from "./Banner";
import Instagram from "./Instagram";
import Tourdata from "./Tourdata";
import BandInfo from "./BandInfo";
import Videos from "./Videos";
import Booking from "./Booking";
import Menu from "./Menu";
import NewAlbum from "./NewAlbum";
import Welcome from "./Welcome";

export default function Main() {
    const { hash } = useLocation();
    useEffect(() => {
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ block: "start" });
            }
        }

        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual"
        }
    }, [hash]);

    window.history.replaceState("", "", window.location.pathname);

    if(useMobileLandscapeQuery()) {
        return <Landscape>Please view in portrait mode</Landscape>;
    }
    
    return (
        <>
            <Mobile><Welcome /></Mobile>
            <Banner />

            <Desktop>
                <Instagram />
            </Desktop>
            
            <Mobile>
                <NewAlbum />
            </Mobile>
            
            <Tourdata />
            <BandInfo />
            <Videos />
            <Booking />

            <Desktop>
                <Maps className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d79526.1950764173!2d11.889973084336956!3d51.47296065549795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a66347fcfc709f%3A0x4236659f806e460!2sHalle%20(Saale)!5e0!3m2!1sde!2sde!4v1711189105875!5m2!1sde!2sde" title="maps" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </Desktop>

            <Copyright>
                <span>&copy; 2024 The Jeals. All rights reserved.</span>
            </Copyright>

            <Menu />
        </>
    );
}

const Maps = styled.iframe`
    width: 100vw;
    height: 40vh;
    background-color: var(--bg-dark-0);
    border: none;
    filter: grayscale(1) invert(1);
`;

const Copyright = styled.div`
    padding: 3vh 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 3.5vw;
        color: var(--fg-light-1);
        border-top: 0.1vh solid var(--fg-light-1);
        padding: 1vh 5vw 0 5vw;
    }

    @media ${bpDesktopQuery} {
        span {
            font-size: 1.25vh;
            padding: 1vh 5vw 0 5vw;
        }
    }

    @media ${bpMobileQuery} {
        background-color: var(--bg-dark-0);
    }
`;

const Landscape = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-dark-0);
    color: var(--fg-light-0);
    font-family: var(--font-1);
    width: 100vw;
    height: 100vh;
`;