import styled from "styled-components";
import { Desktop, bpDesktopQuery, bpMobileQuery } from "../breakpoints";
import Socials from "./Socials";
import { useEffect, useState } from "react";

export default function Booking() {
    const [value, setValue] = useState('');

    useEffect(() => {
        fetch('https://admin.the-jeals.com/api/content/item/texts?filter={key:"booking"}')
        .then(res => res.json())
        .then(data => {
            setValue(data.content)
        });
    }, []);

    return (
        <StyledBooking id="booking">
            <Desktop>
                <Socials></Socials>
            </Desktop>

            <h1>Booking</h1>
            <div className="container">
                <div className="content" dangerouslySetInnerHTML={{__html: value}}></div>

                <Epk href="https://drive.google.com/drive/folders/1og4T1EniXeHXM7u_0daPYhAShx7OPsK2" target="_blank" rel="noreferrer">
                    <img src="img/mailbox.svg" alt="EPK"></img>
                    <span>EPK</span>
                </Epk>
            </div>
        </StyledBooking>
    );
}

const StyledBooking = styled.section`
    width: 90vw;
    padding: 5vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg-dark-0);
    color: var(--fg-light-0);
    font-family: var(--font-1);
    font-size: 4vw;
    font-weight: 200;
    font-style: normal;

    p {
        margin-block-start: unset;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;
    }

    .content a {
        text-decoration: none;
        color: inherit
    }

    .content strong {
        font-weight: 400;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media ${bpDesktopQuery} {
        padding: 5vh 0;
        font-size: 2vh;
        margin: 0 auto;
        justify-content: center;

        .content {
            width: unset;
        }

        .container {
            flex-direction: row;
            align-items: unset;
        }

        h1 {
            font-size: 11vh;
            transform: rotate(-5deg);
            font-family: "Space Grotesk", sans-serif;
            margin: 5vh;
        }
    }
`;

const Epk = styled.a`
    margin-top: 5vh;
    position: relative;

    img {
        transform: rotate(5deg);
        width: 35vh;
    }

    span {
        background-color: var(--fg-light-0);
        color: var(--fg-dark-0);
        font-family: var(--font-1);
        font-size: 5vh;
        font-weight: 600;
        text-decoration: underline;
        width: 15vh;
        height: 15vh;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transform: rotate(-5deg);
        top: 50%;
        left: 5%;
        position: absolute;
    }

    @media ${bpMobileQuery} {
        img {
            width: 50vw;
        }

        span {
            font-size: 5vw;
            width: 15vw;
            height: 15vw;
            top: 75%;
            left: 5%;
            position: absolute;
        }
    }
`;