import { useRef } from "react";
import { Button, Form, FormField, Header, Input } from "semantic-ui-react";

export default function User() {
    const oldRef = useRef(null);
    const newRef = useRef(null);
    const repRef = useRef(null);

    function logout() {
        fetch('https://api.the-jeals.com/logout.php', {
            credentials: 'include'
        })
        .then(() => {
            window.location.reload();
        });
    }

    function change() {
        if(newRef.current.inputRef.current.value === "") return;
        if(newRef.current.inputRef.current.value !== repRef.current.inputRef.current.value) {
            alert("passwords do not match");
            return;
        }

        const data = new FormData();
        data.append("old", oldRef.current.inputRef.current.value);
        data.append("new", newRef.current.inputRef.current.value);

        fetch('https://api.the-jeals.com/change_password.php', {
            method: 'POST',
            body: data,
            credentials: 'include'
        })
        .then(res => {
            if(res.ok) {
                alert("password change");
            } else {
                alert("Old password does not match");
            }
        });
    }

    return (
        <>
            <Button onClick={logout}>Logout</Button>

            <Header dividing>Passwort ändern</Header>
            <Form>
                <FormField>
                    <label>Altes Password</label>
                    <Input ref={oldRef} type="password" />
                </FormField>
                
                <FormField>
                    <label>Neues Password</label>
                    <Input ref={newRef} type="password" />
                </FormField>
                
                <FormField>
                    <label>Neues Password (Wiederholen)</label>
                    <Input ref={repRef} type="password" />
                </FormField>

                <Button onClick={change}>Ändern</Button>
            </Form>
        </>
    )
}