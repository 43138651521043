import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { Button, Form, FormField, Icon, Input, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";

export default function Tourdata() {
    const [tourdata, setTourdata] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalTourdata, setModalTourdata] = useState({});

    useEffect(() => {
        fetch('https://api.the-jeals.com/tourdata.php')
        .then(res => res.json())
        .then(data => {
            setTourdata(data)
        });
    }, []);

    function openModal(tourdata) {
        setModalTourdata(tourdata);
        setIsOpen(true);
    }

    function closeModal(update) {
        setIsOpen(false);
        if(update) {
            fetch('https://api.the-jeals.com/tourdata.php')
            .then(res => res.json())
            .then(data => {
                setTourdata(data)
            });
        }
    }

    function deleteTourdata(td) {
        fetch('https://api.the-jeals.com/tourdata.php?id=' + td.id, {
            method: 'DELETE',
            credentials: 'include'
        })
        .then(res => {
            if(res.ok) {
                fetch('https://api.the-jeals.com/tourdata.php')
                .then(res => res.json())
                .then(data => {
                    setTourdata(data)
                });
            } else {
                alert("could not delete item");
            }
        })
    }

    return (
        <>
            <Button onClick={() => openModal({})}>Neuen Punkt hinzufügen</Button>
            <Table celled>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Datum</TableHeaderCell>
                        <TableHeaderCell>Location</TableHeaderCell>
                        <TableHeaderCell>Bild</TableHeaderCell>
                        <TableHeaderCell>Aktion</TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {tourdata.map(td => (
                        <TableRow key={td.id}>
                            <TableCell>{td.date}</TableCell>
                            <TableCell>{td.location}</TableCell>
                            <TableCell><img src={"https://www.the-jeals.com/" + td.image} width={64} alt=""/></TableCell>
                            <TableCell>
                                <Button icon onClick={() => openModal(td)}>
                                    <Icon name="edit" />
                                </Button>
                                <Button icon onClick={() => deleteTourdata(td)}>
                                    <Icon name="trash alternate" />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>

                <TourdataPopup open={modalIsOpen} tourdata={modalTourdata} close={closeModal} />
            </Table>
        </>  
    );
}

function TourdataPopup({tourdata, open, close}) {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '',
            padding: '20px'
        },
    };

    const fRef = useRef(null);
    const dRef = useRef(null);
    const lRef = useRef(null);

    function save() {
        const data = new FormData();
        data.append("file", fRef.current.inputRef.current.files[0]);
        data.append("date", dRef.current.inputRef.current.value);
        data.append("location", lRef.current.inputRef.current.value);

        if(tourdata.id !== undefined) {
            data.append("id", tourdata.id);
        }

        fetch('https://api.the-jeals.com/tourdata.php', {
            method: 'POST',
            body: data,
            credentials: 'include'
        })
        .then(res => {
            if(res.ok) {
                close(true);
            } else {
                alert("wrong credentials")
            }
        });
    }

    return (
        <Modal isOpen={open} onRequestClose={() => close(false)} style={customStyles} ariaHideApp={false}>
            <Form>
                <FormField>
                    <label>Location</label>
                    <Input type="text" ref={lRef} placeholder="Location" defaultValue={tourdata.location} />
                </FormField>

                <FormField>
                    <label>Datum</label>
                    <Input type="date" ref={dRef} defaultValue={tourdata.date} />
                </FormField>

                <FormField>
                    <label>Bild</label>
                    <Input type="file" ref={fRef} accept="image/png, image/jpeg" />
                </FormField>

                <Button type="submit" onClick={save} primary>Hinzufügen</Button>
            </Form>
        </Modal>
    );
}